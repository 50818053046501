@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryColor: #1DA57A;
  --primaryDark: #001d3d;
  --secondaryColor: #00425A;
  --secondaryDark: #003566;
  --code-color: darkred;
  --code-bg-color: #f6f6f6;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

ul {
  padding-inline-start: 0;
}

.trigger {
  padding: 10px;
  font-size: 20px;
  line-height: 64px;
  color: var(--secondaryColor);
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout-background {
  background: #f0f2f5;
}

.trigger:hover {
  color: var(--primaryDark);
}

.ant-layout-sider-children {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-menu-item-selected {
  border-radius: 10px;
}

.company-title {
  padding: 2px 15px;
}

.ant-layout-sider {
  background: var(--secondaryColor);
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}


.page-layout {
  padding: 0 24px 10px;
}

.header {
  box-shadow: 0 6px 20px 15px rgb(0 0 0 / 3%);
  background-color: white;
  padding: 0 20px;
}

.shadow {
  box-shadow: 0px 6px 13px -6px rgba(0, 0, 0, 0.1);
}

.not-found-page-wraper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
}

.not-found {
  text-shadow: -8px 0 0 #fff;
}

.list-item-selected {
  background-color: #DEF5E5;
  border-left: 6px solid var(--primaryColor);
  font-weight: bold;
}

/*Ant design*/

.ant-select-arrow {
  color: var(--primaryColor);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent \9; /* ie fallback png background image */
  z-index: 9999;
  color: white;
}

@media only screen and (max-device-width: 480px) {
  .site-layout {
    padding: 0;
  }

  .page-layout {
    padding: 0 10px 10px;
  }

  .company-title {
    display: none;
  }
}


* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}
